import Link from 'next/link';

import ProfileIconAndText from 'components/Common/header/Header/ProfileIconAndText';
import { getPageURL, ROUTE_CONFIG } from 'services/hostPortal.service';

const SignInButton = ({
  pathName,
  onHomeScreenBanner,
  onScrollNotInHomeScreen
}) => (
  <Link
    className='md:block hidden'
    href={getPageURL({
      pageName: ROUTE_CONFIG.LOGIN.label,
      queryParams: {
        redirectTo: pathName
      }
    })}
  >
    <a>
      <ProfileIconAndText
        {...{ onScrollNotInHomeScreen, onHomeScreenBanner }}
      />
    </a>
  </Link>
);

export default SignInButton;
