import Image from 'next/image';
import Link from 'next/link';

import { staticMediaStoreBaseURL } from 'config/common';
import { getPageURL, ROUTE_CONFIG } from 'services/hostPortal.service';

const HaflaLogoMobile = ({ logoUrl }) => (
  <div className='md:hidden'>
    <Link
      className='cursor-pointer'
      href={getPageURL({
        pageName: ROUTE_CONFIG.HOME_PAGE.label
      })}
    >
      <a>
        <Image
          src={`${staticMediaStoreBaseURL}${logoUrl}`}
          height={32}
          width={100}
          alt='Hafla'
          className='inline-block cursor-pointer w-16 md:w-20'
        />
      </a>
    </Link>
  </div>
);

export default HaflaLogoMobile;
