import Image from 'next/image';
import Link from 'next/link';

import { staticMediaStoreBaseURL } from 'config/common';
import { getPageURL, ROUTE_CONFIG } from 'services/hostPortal.service';

const HaflaLogoWeb = ({ logoUrl }) => (
  <Link
    className='cursor-pointer'
    href={getPageURL({
      pageName: ROUTE_CONFIG.HOME_PAGE.label
    })}
  >
    <a>
      <Image
        src={`${staticMediaStoreBaseURL}${logoUrl}`}
        alt='Hafla'
        height={40}
        width={120}
        className='inline-block hafla-header-img md:26px cursor-pointer'
      />
    </a>
  </Link>
);

export default HaflaLogoWeb;
